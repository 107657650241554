import React, {useContext, useEffect} from "react";
import {LayoutContext} from "../components/Layout/Layout";

const NoMatch = () => {
    const [setLayoutState] = useContext(LayoutContext);

    useEffect(() => {
        setLayoutState({title: '404'});
    }, [setLayoutState]);

    return (
        <>
            Seite wurde nicht gefunden
        </>
    );
}

export default NoMatch;
let backendHost;
const apiVersion = 'v2';

const hostname = window && window.location && window.location.hostname;

if (/^app\./.test(hostname)) {
    backendHost = `https://${hostname.replace(/^(app\.)/,'')}`;
} else {
    backendHost = 'https://oedsbach.laravel-senertec.ddev.site';
}

export const BACKEND_HOST = backendHost;
export const API_ROOT = `${backendHost}/api/${apiVersion}`;
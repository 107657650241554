import React, {useState} from "react";
import {Alert, Box, Button, Checkbox, Divider, FormControl, FormControlLabel, TextField} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import {API_ROOT} from "../../../api-config";
import db from "../../../db";
import FullScreenDialog from "../../FullScreenDialog";

const FinishDialog = (props) => {
    const [partDone, setPartDone] = useState(false);
    const [partDoneNote, setPartDoneNote] = useState('');

    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    const finishJob = () => {
        setLoading(true);

        let finishedJob = props.job;

        if (partDone) {
            finishedJob.job_partdone_text = partDoneNote;
        }

        axios.post(`${API_ROOT}/finish`, {job: finishedJob, state: partDone ? 'partdone' : 'done'})
            .then(function (response) {
                return db.jobs.delete(parseInt(props.job.job_id, 10));
            })
            .then(() => {
                props.setOpen(false);
            })
            .catch((error) => {
                setLoading(false);

                if (error.message) {
                    setError(error.message);
                } else {
                    setError('Unbekannter Fehler');
                }
            });
    };

    return (
        <FullScreenDialog title={'Auftrag #' + props.job.job_number + ' erledigen'} setOpen={props.setOpen}
                          open={props.open}>
            <Box sx={{textAlign: 'center', mt: 2}}>
                <FormControlLabel
                    control={<Checkbox value={partDone} onChange={(e) => {
                        setPartDone(e.target.checked)
                    }} name="partdone" color="primary"/>}
                    label="Teilerledigt"
                />

                {partDone ?
                    <div>
                        <br/>Teilerledigte Aufträge werden wieder in der Übersicht "Offene Aufträge" angezeigt.
                        <br/>
                        Hier kann eine Nachricht für den nächsten Bearbeiter hinterlassen werden:
                        <br/>
                        <br/>
                        <FormControl sx={{m: 1}} fullWidth>
                            <TextField
                                name="partdone_note"
                                label="Hinweis"
                                multiline
                                rows={6}
                                value={partDoneNote}
                                onChange={(e) => {
                                    setPartDoneNote(e.target.value)
                                }}
                                fullWidth
                            />
                        </FormControl>
                    </div>
                    : ''
                }
                <br/><br/>
                <Divider/>
                <br/><br/>

                {error ? <Alert severity="error">{error}</Alert> : <></>}

                <Button onClick={finishJob} type="submit" variant="contained"
                        disabled={loading || (partDone && !partDoneNote)}
                        startIcon={<CheckIcon/>}>Erledigen</Button>
            </Box>
        </FullScreenDialog>

    );
}

export default FinishDialog;
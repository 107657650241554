import React, {createContext, useState} from "react";
import Navigation from "./Navigation";
import Header from "./Header";
import Box from "@mui/material/Box";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {Outlet, useNavigate} from "react-router-dom";
import {Toolbar} from "@mui/material";

export const LayoutContext = createContext(null);

const Layout = props => {
    const navigate = useNavigate();
    const [layoutState, setLayoutState] = useState({});

    const [drawer, setDrawer] = React.useState({
        drawerVisible: false
    });

    const toggleDrawer = (open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setDrawer({...drawer, drawerVisible: open});
    };

    return (
        <React.Fragment>
            <Box sx={{minHeight: '100vh', backgroundColor: 'grey.100'}}>
                <Navigation drawerVisible={drawer.drawerVisible} toggleDrawer={toggleDrawer}/>
                <Header title={layoutState.title} toggleDrawer={toggleDrawer}/>
                <Toolbar/>
                {layoutState.showBackButton
                    ?
                    <Box sx={{
                        position: 'fixed',
                        top: '8px',
                        left: 0,
                        width: '100%',
                        zIndex: 1000,
                    }}>
                        <Toolbar/>
                        <Box onClick={() => navigate(-1)} sx={{
                            display: 'flex',
                            alignItems: 'center',
                            minHeight: '50px',
                            px: {
                                xs: 2,
                                sm: 3
                            },
                            fontWeight: 'bold',
                            backgroundColor: '#fff',
                            borderBottom: 1,
                            borderColor: 'grey.300',
                            pr: '24px',
                            color: 'grey.500',
                            '&:hover': {
                                cursor: 'pointer'
                            }
                        }}>
                            <ArrowBackIosIcon/>
                        </Box>
                    </Box>
                    :
                    ''
                }

                <Box sx={{mt: 1, p: 3}}>
                    <Box sx={{mt: layoutState.showBackButton ? '50px' : 0}}>
                        <LayoutContext.Provider value={[setLayoutState]}>
                            <Outlet/>
                        </LayoutContext.Provider>
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    );
}

export default Layout;
import React, {useEffect} from 'react';
import Router from './Router'
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {BrowserRouter, MemoryRouter} from "react-router-dom";
import ScrollToTop from "../ScrollToTop";
import axios from "axios";
import {AuthenticationProvider} from "../../context/AuthenticationContext";
import CssBaseline from '@mui/material/CssBaseline';
import {SynchronizationProvider} from "../../context/SynchronizationContext";
import { LocalizationProvider } from '@mui/x-date-pickers';
import moment from "moment";
import 'moment/locale/de';
import {SettingsProvider} from "../../context/SettingsContext";
import ErrorBoundary from "../ErrorBoundary";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";

axios.defaults.withCredentials = true;
axios.defaults.timeout = 10000;

let theme = createTheme({
    palette: {
        primary: {
            main: '#005545'
        },
        secondary: {
            main: '#00a77e',
        },
    },
    shape: {
        borderRadius: 6
    },
});

theme = createTheme(theme, {
    shadows: {
        ...theme.shadows, ...{
            0: "none",
            1: "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;",
            2: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;",
            3: "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;",
            4: "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;",
            5: "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;",
        },
    }
});

theme = createTheme(theme, {
    components: {
        MuiFab: {
            // defaultProps: {
            //     sx: {
            //         boxShadow: 2
            //     }
            // },
            styleOverrides: {
                root: {
                    boxShadow: theme.shadows[2]
                }
            }
        }
    }
});

const App = () => {
    useEffect(() => {
        moment.locale('de')
    }, []);

    const bootDate = new Date();
    const routingPart = <ErrorBoundary>
        <ScrollToTop>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <AuthenticationProvider>
                    <SynchronizationProvider bootDate={bootDate}>
                        <SettingsProvider>
                            <Router/>
                        </SettingsProvider>
                    </SynchronizationProvider>
                </AuthenticationProvider>
            </LocalizationProvider>
        </ScrollToTop>
    </ErrorBoundary>;

    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                {process.env.NODE_ENV === 'production' ? (
                    <MemoryRouter>{routingPart}</MemoryRouter>
                ) : (
                    <BrowserRouter>{routingPart}</BrowserRouter>
                )}
            </ThemeProvider>
        </React.Fragment>
    );
}

export default App;
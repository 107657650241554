import {useEffect, useState} from 'react';

const usePersistentState = (localStorageKey, defaultValue) => {
    localStorageKey = 'persistent-' + localStorageKey;
    const [value, setValue] = useState(() => {
        const localStorageItem = localStorage.getItem(localStorageKey);
        if (localStorageItem === null) return defaultValue;
        try {
            return JSON.parse(localStorageItem);
        } catch (err) {
            return defaultValue;
        }
    });

    useEffect(() => {
        localStorage.setItem(localStorageKey, JSON.stringify(value));
    }, [localStorageKey, value]);

    // Expose the value and the updater function.
    return [value, setValue];
}

export default usePersistentState;
import React, {useContext, useEffect} from "react";
import {LayoutContext} from "../components/Layout/Layout";
import {AuthenticationContext} from "../context/AuthenticationContext";

const Profile = () => {
    const [user] = useContext(AuthenticationContext);
    const [setLayoutState] = useContext(LayoutContext);

    useEffect(() => {
        setLayoutState({title: 'Profil'});
    }, [setLayoutState]);

    return (
        <>
            {user.user_first_name} {user.user_last_name} [ {user.id} ]
        </>
    );
}

export default Profile;
import React, {useContext} from 'react';
import {Navigate, Outlet, Route, Routes} from 'react-router-dom';
import SignIn from "../../routes/SignIn";
import {AuthenticationContext} from "../../context/AuthenticationContext";
import ClientList from "../../routes/Client/List";
import {isEmpty} from 'lodash';
import Profile from "../../routes/Profile";
import JobListOpen from "../../routes/Job/List/Open";
import JobListOwn from "../../routes/Job/List/Own";
import JobListOther from "../../routes/Job/List/Other";
import JobDetail from "../../routes/Job/Detail";
import ClientDetail from "../../routes/Client/Detail";
import InstallationDetail from "../../routes/Installation/Detail";
import Layout from "../Layout/Layout";
import NoMatch from "../../routes/NoMatch";
import JobNavigation from "../Layout/JobNavigation";

const Router = () => {
    const [user] = useContext(AuthenticationContext);

    const RequireAuth = () => {
        if (isEmpty(user)) {
            return <Navigate to="/"/>;
        }

        return <Outlet/>;
    };

    return (
        <Routes>
            <Route index path='/' element={isEmpty(user) ? <SignIn/> : <Navigate to="/jobs"/>}/>
            <Route element={<Layout title="test"/>}>
                <Route element={<RequireAuth/>}>
                    <Route path="jobs" element={<JobNavigation/>}>
                        <Route path="open" element={<JobListOpen/>}/>
                        <Route index element={<JobListOwn/>}/>
                        <Route path="other" element={<JobListOther/>}/>
                        <Route path='*' element={<NoMatch/>}/>
                    </Route>
                    <Route path="jobs/detail/:jobId" element={<JobDetail/>}/>
                    <Route path="jobs/detail/:jobId/:installationId" element={<InstallationDetail/>}/>
                    <Route path='clients' element={<ClientList/>}/>
                    <Route path="clients/detail/:clientId" element={<ClientDetail/>}/>
                    <Route path='profile' element={<Profile/>}/>
                </Route>
                <Route path='*' element={<NoMatch/>}/>
            </Route>
        </Routes>
    );
}

export default Router;
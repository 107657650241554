import {Box, Fab} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import React, {useState} from "react";
import AssignDialog from "../Dialog/AssignDialog";

const OpenActions = (props) => {
    const [startOpen, setStartOpen] = useState(false);

    return (
        <>
            <Box sx={{position: 'fixed', zIndex: 1000, bottom: 24, right: 24}}>
                <Fab onClick={() => {
                    setStartOpen(true)
                }} color="info" variant="extended">
                    <CheckIcon sx={{mr: 1}}/>
                    Annehmen
                </Fab>
            </Box>

            <AssignDialog job={props.job} open={startOpen} setOpen={setStartOpen}/>
        </>
    );
}

export default OpenActions;
import React, {useState} from "react";
import {Alert, Box, Button, FormControl, TextField} from "@mui/material";
import axios from "axios";
import {API_ROOT} from "../../../api-config";
import db from "../../../db";

import CheckIcon from '@mui/icons-material/Check';
import FullScreenDialog from "../../FullScreenDialog";
import {MobileDateTimePicker} from "@mui/x-date-pickers";

const AssignDialog = (props) => {
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [executionDateTime, setExecutionDateTime] = useState(null);
    const assignJob = () => {
        setLoading(true);

        let data = {job_id: props.job.job_id};

        if (executionDateTime) {
            data.job_execution_date_time = executionDateTime.format();
        }

        axios.post(`${API_ROOT}/assign`, data)
            .then(function (response) {
                return db.jobs.update(parseInt(props.job.job_id, 10), response.data);
            })
            .then(() => {
                props.setOpen(false);
            })
            .catch((error) => {
                setLoading(false);

                if (error.message) {
                    setError(error.message);
                } else {
                    setError('Unbekannter Fehler');
                }
            });
    };

    return (
        <FullScreenDialog title={'Auftrag #' + props.job.job_number + ' annehmen'} setOpen={props.setOpen}
                          open={props.open}>
            <FormControl sx={{my: 1}} fullWidth>
                <MobileDateTimePicker
                    renderInput={(props) => <TextField {...props} />}
                    name="job_execution"
                    value={executionDateTime}
                    label="Ausführungsdatum"
                    onChange={setExecutionDateTime}
                />
            </FormControl>

            <br/>

            {error ? <Alert severity="error">{error}</Alert> : <></>}

            <Box sx={{textAlign: 'center', mt: 2}}>
                <Button onClick={assignJob} color="info" type="submit" variant="contained"
                        disabled={loading}
                        startIcon={<CheckIcon/>}>Annehmen</Button>
            </Box>
        </FullScreenDialog>
    );
}

export default AssignDialog;
import React from 'react';
import {Alert, AlertTitle, Button} from "@mui/material";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {error: null, errorInfo: null};
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {

            return <Alert sx={{m: 2}} severity="error">
                <AlertTitle>Fehler</AlertTitle>
                {this.state.error && this.state.error.toString()}
                {/*<br />*/}
                {/*{this.state.errorInfo.componentStack}*/}
                <br/>
                <br/>
                <Button variant="contained" onClick={() => {
                    window.location.reload()
                }}>Seite neu laden</Button>
            </Alert>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
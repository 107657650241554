import React, {useContext} from "react";
import {AuthenticationContext} from "../../../context/AuthenticationContext";
import JobGroups from "../../../components/Job/JobGroups";

const Component = () => {
    const [user] = useContext(AuthenticationContext);

    const filterCallback = (job) => {
        return job.user_id === user.id && (job.job_state === null || job.job_state === 1);
    };

    return (
        <JobGroups filterCallback={filterCallback}/>
    );
}

export default Component;
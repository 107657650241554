import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {LayoutContext} from "../../components/Layout/Layout";
import {useLiveQuery} from "dexie-react-hooks";
import db from "../../db";
import {Chip, Grid} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import EventIcon from '@mui/icons-material/Event';
import OperatingDialog from "../../components/Installation/Dialog/OperatingDialog";
import InstalllationDetailCard from "../../components/Installation/Card/DetailCard";
import FuelLabel from "../../components/Installation/Label/FuelLabel";
import TypeLabel from "../../components/Installation/Label/TypeLabel";

const Detail = () => {
    let {jobId, installationId} = useParams();
    const [setLayoutState] = useContext(LayoutContext);
    const [operatingOpen, setOperatingOpen] = useState(false);

    const job = useLiveQuery(
        async () => {
            return await db.jobs.get(parseInt(jobId, 10));
        },
        [installationId]
    );

    const installation = useLiveQuery(
        async () => {
            return await db.installations.get(parseInt(installationId, 10));
        },
        [installationId]
    );

    useEffect(() => {
        if (!job) {
            return;
        }

        let title = 'Auftrag #' + job.job_number;

        if (installation) {
            title += ' (' + installation.operating.type_real + ')'
        }

        setLayoutState({
            title: title,
            showBackButton: true
        });
    }, [job, installation, setLayoutState]);

    if (!job || !installation) {
        return null;
    }

    return (
        <>
            <OperatingDialog installation={installation} open={operatingOpen} setOpen={setOperatingOpen}/>

            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <InstalllationDetailCard
                        onClick={() => {setOperatingOpen(true)}}
                        installation={installation}
                        identifier="operating">
                        Anlagentyp: <b><TypeLabel type={installation.operating.type}/></b><br/>
                        Brennstoff: <b><FuelLabel fuel={installation.operating.fuel}/></b><br/>
                        Anlagenbezeichnung: {installation.operating.type_real ?
                        <b>{installation.operating.type_real}</b> : <i>Keine Angabe</i>}<br/>
                        Fabrikations-Nr: {installation.operating.manufacture ?
                        <b>{installation.operating.manufacture}</b> : <i>Keine Angabe</i>}<br/>
                        Seriennummer: {installation.operating.serial ? <b>{installation.operating.serial}</b> :
                        <i>Keine Angabe</i>}
                    </InstalllationDetailCard>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InstalllationDetailCard installation={installation} identifier="service">
                        <Chip variant="outlined" sx={{mr: 1}} icon={<EventIcon/>} label="xx.xx.xxxx xx:xx"/>
                        <Chip variant="outlined" sx={{mr: 1}} icon={<HourglassBottomIcon/>} label="1 h"/>
                        <Chip variant="outlined" sx={{mr: 1}} icon={<PersonIcon/>} label="Test Name"/>
                    </InstalllationDetailCard>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InstalllationDetailCard installation={installation} identifier="parts">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                        ut labore et dolore.
                    </InstalllationDetailCard>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InstalllationDetailCard installation={installation} identifier="maintenance">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                        ut labore et dolore.
                    </InstalllationDetailCard>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InstalllationDetailCard installation={installation} identifier="measure">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                        ut labore et dolore.
                    </InstalllationDetailCard>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InstalllationDetailCard installation={installation} identifier="evaluation">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                        ut labore et dolore.
                    </InstalllationDetailCard>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InstalllationDetailCard installation={installation} identifier="checklists">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                        ut labore et dolore.
                    </InstalllationDetailCard>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InstalllationDetailCard installation={installation} identifier="attachments">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                        ut labore et dolore.
                    </InstalllationDetailCard>
                </Grid>
            </Grid>
        </>
    );
}

export default Detail;
import Dexie from 'dexie';
import {forEach} from "lodash";

const db = new Dexie('SenerTec');

db.version(13).stores({
    jobs: 'job_id,user_id,job_state,client_id,unsynced',
    installations: 'installation_id,job_id,unsynced',
    clients: 'client_id,client_name,unsynced',
    users: 'id,unsynced',
    settings: 'key,unsynced',
});

export const syncTables = (tables) => {
    let promises = [];

    forEach(tables, (tableItems, tableName) => {
        promises.push(db[tableName].clear());
        promises.push(db[tableName].bulkPut(tableItems));
    });

    return Promise.all(promises);
}

export const getUnsyncedChanges = async () => {
    let promises = [];

    db.tables.forEach((table) => {
        promises.push(new Promise(async function (resolve, reject) {
            const records = await table
                .where('unsynced')
                .equals(1)
                .toArray();

            resolve({
                tableName: table.name,
                records: records
            });
        }));
    });

    return Promise.all(promises).then((unsyncedGroups) => {
        let result = {};

        unsyncedGroups.forEach((unsyncedGroup) => {
            if (unsyncedGroup.records.length === 0) {
                return;
            }

            result[unsyncedGroup.tableName] = unsyncedGroup.records;
        });

        return result;
    });
}

export default db;
import {Card, CardActionArea, CardContent, Grid} from "@mui/material";
import React from "react";
import {useNavigate} from "react-router-dom";
import AreaHeader from "../AreaHeader";

const PreviewCard = (props) => {
    const navigate = useNavigate();
    return (
        <Card sx={{minWidth: 275}}>
            <CardActionArea
                onClick={() => navigate("/jobs/detail/" + props.job.job_id + "/" + props.installation.installation_id)}>
                <CardContent>
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={4} md={3}>
                            <AreaHeader installation={props.installation} areaIdentifier="operating"/>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <AreaHeader installation={props.installation} areaIdentifier="service"/>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <AreaHeader installation={props.installation} areaIdentifier="parts"/>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <AreaHeader installation={props.installation} areaIdentifier="maintenance"/>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <AreaHeader installation={props.installation} areaIdentifier="measure"/>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <AreaHeader installation={props.installation} areaIdentifier="evaluation"/>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <AreaHeader installation={props.installation} areaIdentifier="checklists"/>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <AreaHeader installation={props.installation} areaIdentifier="attachments"/>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export default PreviewCard;
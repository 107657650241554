import React, {useContext, useEffect} from "react";
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import BottomNavigation from "@mui/material/BottomNavigation";
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import LayersIcon from '@mui/icons-material/Layers';
import Box from "@mui/material/Box";
import {LayoutContext} from "./Layout";
import usePersistentState from "../../hooks/usePersistentState";

const navigationItems = {
    open: {
        title: 'Offene Aufträge',
        path: "/jobs/open"
    },
    own: {
        title: 'Meine Aufträge',
        path: "/jobs"
    },
    other: {
        title: 'Andere Aufträge',
        path: "/jobs/other"
    }
};

const Layout = () => {
    const [value, setValue] = usePersistentState('jobs-navigation', "own");

    const [setLayoutState] = useContext(LayoutContext);
    let navigate = useNavigate();

    const {pathname} = useLocation();

    useEffect(() => {
        for (const [key, item] of Object.entries(navigationItems)) {
            if (item.path === pathname) {
                setLayoutState({title: item.title});
                setValue(key);

                break;
            }
        }
    }, [pathname, setLayoutState, setValue]);

    return (
        <>
            <Box sx={{marginBottom: (56 + 10) + 'px'}}>
                <Outlet/>
            </Box>

            <Box sx={{
                width: '100%',
                position: 'fixed',
                bottom: 0,
                right: 0,
                borderTop: 1,
                borderColor: 'grey.300',
            }}>
                <BottomNavigation
                    value={value}
                    onChange={(event, selectedValue) => {
                        setLayoutState({title: navigationItems[selectedValue].title});
                        setValue(navigationItems[selectedValue].path);
                        navigate(navigationItems[selectedValue].path);
                    }}
                >
                    <BottomNavigationAction icon={<LayersIcon/>} label="Offen" value="open"/>
                    <BottomNavigationAction icon={<PersonIcon/>} label="Meine" value="own"/>
                    <BottomNavigationAction icon={<PeopleIcon/>} label="Andere" value="other"/>
                </BottomNavigation>
            </Box>
        </>
    );
}

export default Layout;
import React, {useContext, useEffect, useState} from "react";
import {Chip, Stack} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import {AuthenticationContext} from "../../context/AuthenticationContext";

const getDistance = function (lat1, lon1, position) {
    if (!position.latitude || !position.longitude) {
        return '?';
    }

    let lat2 = position.latitude;
    let lon2 = position.longitude;

    let toRad = function (Value) {
        /** Converts numeric degrees to radians */
        return Value * Math.PI / 180;
    };

    let R = 6371; // Radius of the earth in km
    let dLat = toRad(lat2 - lat1);  // Javascript functions in radians
    let dLon = toRad(lon2 - lon1);
    let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c; // Distance in km
    return d.toFixed(2).replace(/\./g, ',');
}

const StateStack = (props) => {

    const [user] = useContext(AuthenticationContext);

    const [position, setPostion] = useState({});

    useEffect(() => {
        const refreshCoordinates = () => {
            navigator.geolocation.getCurrentPosition(function (position) {
                setPostion(position.coords);
            });
        };
        const interval = setInterval(() => {
            refreshCoordinates();
        }, 1000 * 60);

        refreshCoordinates();

        return () => {
            clearInterval(interval);
        }
    }, []);

    return (
        <Stack
            direction="row"
            alignItems="center"
            sx={{flexFlow: 'row wrap'}}
        >
            {props.job.user
                ?
                <Chip variant={user.id === props.job.user.id ? 'filled' : 'outlined'} sx={{m: 1}} icon={<PersonIcon/>}
                      label={props.job.user.user_first_name + ' ' + props.job.user.user_last_name}/>
                :
                ''
            }

            {props.job.job_state === 1
                ?
                <Chip label="Teilerledigt" sx={{m: 1}}/> : props.job.job_state === 2 ?
                    <Chip label="Erledigt" sx={{m: 1}} color="success"/> :
                    <></>}
            
            {props.job.job_urgency === 10 ?
                <Chip label="Dringend" color="warning"
                      sx={{mr: 1}}/>
                : props.job.job_urgency === 20 ?
                    <Chip label="Sehr dringend" sx={{m: 1}} color="error"/>
                    : <></>
            }

            {props.children}


            <Chip sx={{ml: 'auto', my: 1, mr: 1}} icon={<MyLocationIcon/>}
                  label={getDistance(props.job.client.client_latitude, props.job.client.client_longitude, position) + ' km'}/>
        </Stack>
    );
}

export default StateStack;
import React, {useContext} from "react";
import {Box, Button, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import {SynchronizationContext} from "../../../context/SynchronizationContext";
import FullScreenDialog from "../../FullScreenDialog";
import {SettingsContext} from "../../../context/SettingsContext";
import {MobileDateTimePicker} from "@mui/x-date-pickers";

const EditDialog = (props) => {
    const [settings] = useContext(SettingsContext);
    const [, enqueueSync] = useContext(SynchronizationContext);
    const [input, setInput] = React.useState({
        job_journey: props.job.job_journey ? props.job.job_journey : '',
        job_custom_journey: props.job.job_custom_journey ? props.job.job_custom_journey : '',
        job_execution: props.job.job_execution ? props.job.job_execution : null,
        job_internal: props.job.job_internal ? props.job.job_internal : ''
    });

    const handleChange = (event) => {
        const value = event.target.value;

        setInput(prevState => {
            return {...prevState, [event.target.name]: value};
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        enqueueSync('jobs', props.job.job_id, input);

        props.setOpen(false);
    };

    return (
        <FullScreenDialog title={'Auftrag #' + props.job.job_number + ' bearbeiten'} setOpen={props.setOpen}
                          open={props.open}>
            <form onSubmit={handleSubmit}>
                <FormControl sx={{my: 1}} fullWidth>
                    {settings.journey_values
                        ?
                        <FormControl fullWidth>
                            <InputLabel id="job_journey-label">Anfahrt (km)</InputLabel>
                            <Select
                                labelId="job_journey-label"
                                id="job_journey"
                                name="job_journey"
                                value={input.job_journey}
                                label="Anfahrt (km)"
                                onChange={handleChange}
                            >
                                <MenuItem value="">
                                    <em>Keine Angabe</em>
                                </MenuItem>
                                {settings.journey_values?.map((name, index) => (
                                    <MenuItem key={index} value={name}>{name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        :
                        <TextField
                            name="job_journey"
                            label="Anfahrt"
                            value={input.job_journey}
                            onChange={handleChange}
                            fullWidth
                        />
                    }
                </FormControl>

                <FormControl sx={{my: 1}} fullWidth>
                    <TextField
                        name="job_custom_journey"
                        label="Fahrtdauer (h)"
                        value={input.job_custom_journey}
                        onChange={handleChange}
                        fullWidth
                    />
                </FormControl>

                <FormControl sx={{my: 1}} fullWidth>
                    <MobileDateTimePicker
                        renderInput={(props) => <TextField {...props} />}
                        name="job_execution"
                        label="Ausführungsdatum"
                        value={input.job_execution}
                        onChange={(value) => {
                            setInput(prevState => {
                                return {...prevState, job_execution: value.format()};
                            });
                        }}
                    />
                </FormControl>

                <FormControl sx={{my: 1}} fullWidth>
                    <TextField
                        name="job_internal"
                        label="Interne Notiz"
                        multiline
                        rows={6}
                        value={input.job_internal}
                        onChange={handleChange}
                        fullWidth
                    />
                </FormControl>

                <Box sx={{textAlign: 'center', mt: 2}}>
                    <Button type="submit" variant="contained" startIcon={<CheckIcon/>}>Speichern</Button>
                </Box>
            </form>
        </FullScreenDialog>
    );
}

export default EditDialog;
import React from "react";

const Labels = {
    natural_gas: 'Erdgas',
    liquid_gas: 'Flüssiggas',
    fuel_oil: 'Heizöl',
    rape_oil: 'Rapsöl',
};

const FuelLabel = (props) => {

    return (
        <>{props.fuel ? (Labels[props.fuel] ? Labels[props.fuel] : props.fuel) : 'Brennstoff fehlt!'}</>
    );
}

export default FuelLabel;
import React, {useState} from "react";
import {Alert, Box, Button} from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import axios from "axios";
import {API_ROOT} from "../../../api-config";
import db from "../../../db";
import FullScreenDialog from "../../FullScreenDialog";

const CancelDialog = (props) => {
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    const cancelJob = () => {
        setLoading(true);

        axios.post(`${API_ROOT}/cancel`, {job_id: props.job.job_id})
            .then(function (response) {
                return db.jobs.update(parseInt(props.job.job_id, 10), response.data);
            })
            .then(() => {
                props.setOpen(false);
            })
            .catch((error) => {
                setLoading(false);

                if (error.message) {
                    setError(error.message);
                } else {
                    setError('Unbekannter Fehler');
                }
            });
    };

    return (
        <FullScreenDialog title={'Auftrag #' + props.job.job_number + ' abbrechen'} setOpen={props.setOpen}
                          open={props.open}>
            <p>

                Hier kann ein Auftrag wieder abgebrochen werden, falls dieser fälschlicherweise angenommen
                wurde.<br/><br/>
                Vorgenommene (nicht synchronisierte) Änderungen gehen verloren ...
            </p>

            <br/>

            {error ? <Alert severity="error">{error}</Alert> : <></>}

            <Box sx={{textAlign: 'center', mt: 2}}>
                <Button onClick={cancelJob} type="submit" variant="contained" disabled={loading}
                        startIcon={<CancelIcon/>}>Abbrechen</Button>
            </Box>
        </FullScreenDialog>
    );
}

export default CancelDialog;
import React, {useContext} from "react";
import {useParams} from 'react-router-dom';
import {useLiveQuery} from "dexie-react-hooks";
import db from "../../db";
import {LayoutContext} from "../../components/Layout/Layout";
import {Box, Button, ButtonGroup} from "@mui/material";
import Typography from "@mui/material/Typography";
import JobGroups from "../../components/Job/JobGroups";
import PhoneIcon from "@mui/icons-material/Phone";
import DirectionsIcon from "@mui/icons-material/Directions";
import PaperAlert from "../../components/PaperAlert";

const Detail = () => {
    let {clientId} = useParams();
    const [setLayoutState] = useContext(LayoutContext);

    const client = useLiveQuery(
        async () => {
            const parsedClientId = parseInt(clientId, 10);
            const client = await db.clients.get(parsedClientId);

            setLayoutState({title: "Kunde \"" + client.client_name + "\"", showBackButton: true});

            return client
        },
        [clientId]
    );

    if (!client) {
        return null;
    }

    return (
        <>
            <Typography variant="h6" component="div" sx={{textAlign: 'center'}}>
                <Typography variant="body2" color="text.secondary">
                    {client.client_street}<br/>
                    {client.client_zip} {client.client_city}
                </Typography>
            </Typography>

            {client.client_notice && <PaperAlert sx={{my: 2}} severity="info">
                {client.client_notice}
            </PaperAlert>}

            <Box sx={{my: 2, textAlign: 'center'}}>
                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                    <Button endIcon={<PhoneIcon/>}>
                        Anruf
                    </Button>
                    <Button endIcon={<DirectionsIcon/>}>
                        Navigation
                    </Button>
                </ButtonGroup>
            </Box>

            <Box sx={{mt: 4}}>
                <JobGroups filterCallback={(job) => {
                    return job.client_id === parseInt(clientId, 10);
                }}/>
            </Box>

        </>
    );
}

export default Detail;
import React, {useContext} from "react";
import {Avatar, Box, Card, CardActionArea, Divider, Grid, Stack} from "@mui/material";
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import {AuthenticationContext} from "../../context/AuthenticationContext";
import {useLiveQuery} from "dexie-react-hooks";
import db from "../../db";
import {groupBy, isEmpty, orderBy} from "lodash";
import moment from "moment";
import {stringAvatar} from "../../utils/stringAvatar";
import StateStack from "./StateStack";
import LightDivider from "../LightDivider";

const JobGroups = (props) => {
    const navigate = useNavigate();

    const [user] = useContext(AuthenticationContext);

    const jobGroups = useLiveQuery(
        async () => {
            const jobs = await db.jobs
                .filter(props.filterCallback)
                .toArray();

            await Promise.all(jobs.map(async job => {
                job.client = await db.clients.get(job.client_id);
            }));

            await Promise.all(jobs.map(async job => {
                if (job.user_id) {
                    job.user = await db.users.get(job.user_id);
                }
            }));

            return groupBy(orderBy(jobs, 'job_execution'), function (job) {
                if (job.job_execution === null) {
                    return 'Noch nicht eingeplant';
                }

                return moment(job.job_execution).format('DD.MM.YYYY');
            });
        },
        [user.id]
    );

    if (isEmpty(jobGroups)) {
        return <Box sx={{textAlign: 'center', color: 'grey.500', m: 5}}>
            <i>Keine Aufträge gefunden!</i>
        </Box>;
    }

    return (
        <>
            {Object.keys(jobGroups).map((key, index) => (
                <React.Fragment key={index}>
                    <LightDivider sx={index === 0 ? {mt: 0} : {}}>{key}</LightDivider>
                    <Grid container spacing={2} sx={{mb: 4}}>
                        {jobGroups[key].map(job => (
                            <Grid key={job.job_id} item xs={12}>
                                <Card sx={{minWidth: 275}}>
                                    <CardActionArea
                                        onClick={() => navigate("/jobs/detail/" + job.job_id)}>
                                        <Box sx={{p: 2, display: 'flex'}}>
                                            {<Avatar {...stringAvatar(job.client.client_name)} />}
                                            <Stack sx={{px: 2}} spacing={0.5}>
                                                <Typography variant="body2" color="text.secondary">
                                                    {job.client.client_name}<br/>
                                                    {job.client.client_street}<br/>
                                                    {job.client.client_zip} {job.client.client_city}
                                                </Typography>
                                            </Stack>
                                            <Box sx={{marginLeft: 'auto', width: '60%', whiteSpace: 'pre-line'}}>
                                                <Typography fontWeight={700}>#{job.job_number}</Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {job.job_text}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Divider/>
                                        <Box sx={{p: 1, bgcolor: 'background.default'}}>
                                            <StateStack job={job}/>
                                        </Box>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </React.Fragment>
            ))}
        </>
    );
}

export default JobGroups;
import {Card, CardActionArea, CardContent, Typography} from "@mui/material";
import React from "react";
import AreaHeader from "../AreaHeader";

const DetailCard = (props) => {
    return (
        <Card onClick={props.onClick}>
            <CardActionArea>
                <CardContent>
                    <AreaHeader installation={props.installation} areaIdentifier={props.identifier}/>
                    {props.children ?
                        <Typography component="div" sx={{mt: 2}}>{props.children}</Typography> : ''}
                </CardContent>

            </CardActionArea>
        </Card>
    );
}

export default DetailCard;
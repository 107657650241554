import React, {useContext} from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import {Link} from "react-router-dom";
import Badge from "@mui/material/Badge";
import SyncIcon from '@mui/icons-material/Sync';
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled';
import {SynchronizationContext} from "../../context/SynchronizationContext";
import {Fade, LinearProgress} from "@mui/material";

const Header = props => {
    const [syncState, , sync] = useContext(SynchronizationContext);

    return (
        <React.Fragment>
            <AppBar sx={{boxShadow: 'none'}}>
                <Fade in={syncState.running} timeout={500}><LinearProgress color="secondary"/></Fade>
                <Toolbar sx={{mb: '4px'}}>
                    <IconButton onClick={props.toggleDrawer(true)} edge="start" color="inherit" aria-label="menu">
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" sx={{mr: 'auto'}}>
                        {props.title}
                    </Typography>
                    <IconButton sx={{transition: 'color 1s ease', '&.Mui-disabled': {color: 'secondary.light'}}} disabled={syncState.running} onClick={() => sync(true)} color="inherit">
                        <Badge color="warning" variant={syncState.synced ? 'standard' : 'dot'}>
                            {navigator.onLine ? <SyncIcon/> : <SyncDisabledIcon/>}
                        </Badge>
                    </IconButton>
                    <IconButton component={Link} to="/profile" color="inherit">
                        <AccountCircle/>
                    </IconButton>
                </Toolbar>

            </AppBar>

        </React.Fragment>
    );
}

export default Header;
import React, {useContext} from "react";
import {
    Box,
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    TextField
} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import {SynchronizationContext} from "../../../context/SynchronizationContext";
import FullScreenDialog from "../../FullScreenDialog";

const OperatingDialog = (props) => {
    const [, enqueueSync] = useContext(SynchronizationContext);
    const [input, setInput] = React.useState({
        serial: props.installation.operating.serial ? props.installation.operating.serial : '',
        manufacture: props.installation.operating.manufacture ? props.installation.operating.manufacture : '',
        type_real: props.installation.operating.type_real ? props.installation.operating.type_real : '',
        type: props.installation.operating.type ? props.installation.operating.type : '',
        fuel: props.installation.operating.fuel ? props.installation.operating.fuel : '',
    });

    const handleChange = (event) => {
        const value = event.target.value;

        setInput(prevState => {
            return {...prevState, [event.target.name]: value};
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        enqueueSync('installations', props.installation.installation_id, {
            operating: {...props.installation.operating, ...input}
        });

        props.setOpen(false);
    };

    return (
        <FullScreenDialog title={'Anlage "' + props.installation.operating.type_real + '" bearbeiten'} setOpen={props.setOpen}
                          open={props.open}>
            <form onSubmit={handleSubmit}>
                <FormControl sx={{my: 1}} fullWidth>
                    <FormLabel id="type-radio">Anlagentyp</FormLabel>
                    <RadioGroup
                        row
                        name="type"
                        value={input.type}
                        onChange={handleChange}
                    >
                        <FormControlLabel value="msr1" control={<Radio />} label="MSR1" />
                        <FormControlLabel value="msr2" control={<Radio />} label="MSR2" />
                        <FormControlLabel value="msr3" control={<Radio />} label="MSR3" />
                        <FormControlLabel value="stirling" control={<Radio />} label="Stirling" />
                        <FormControlLabel value="dachspro" control={<Radio />} label="Dachs pro 20" />
                    </RadioGroup>
                </FormControl>

                <FormControl sx={{my: 1}} fullWidth>
                    <FormLabel id="type-radio">Brennstoff</FormLabel>
                    <RadioGroup
                        row
                        name="fuel"
                        value={input.fuel}
                        onChange={handleChange}
                    >
                        <FormControlLabel value="natural_gas" control={<Radio />} label="Erdgas" />
                        <FormControlLabel value="liquid_gas" control={<Radio />} label="Flüssiggas" />
                        <FormControlLabel value="fuel_oil" control={<Radio />} label="Heizöl" />
                        <FormControlLabel value="rape_oil" control={<Radio />} label="Rapsöl" />
                    </RadioGroup>
                </FormControl>

                <Divider sx={{my: 3}} />

                <FormControl sx={{my: 1}} fullWidth>
                    <TextField
                        name="type_real"
                        label="Anlagenbezeichnung"
                        value={input.type_real}
                        onChange={handleChange}
                        fullWidth
                    />
                </FormControl>

                <FormControl sx={{my: 1}} fullWidth>
                    <TextField
                        name="manufacture"
                        label="Fabrikations-Nr"
                        value={input.manufacture}
                        onChange={handleChange}
                        fullWidth
                    />
                </FormControl>

                <FormControl sx={{my: 1}} fullWidth>
                    <TextField
                        name="serial"
                        label="Seriennummer"
                        value={input.serial}
                        onChange={handleChange}
                        fullWidth
                    />
                </FormControl>

                <Box sx={{textAlign: 'center', mt: 2}}>
                    <Button type="submit" variant="contained" startIcon={<CheckIcon/>}>Speichern</Button>
                </Box>
            </form>
        </FullScreenDialog>
    );
}

export default OperatingDialog;
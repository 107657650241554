import React from "react";
import {Alert} from "@mui/material";
import Paper from "@mui/material/Paper";

export default function PaperAlert(props) {
    return <Paper sx={props.sx}>
        <Alert variant="outlined" severity={props.severity}>
            {props.children}
        </Alert>
    </Paper>;
}
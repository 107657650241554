import React, {useContext} from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PeopleIcon from '@mui/icons-material/People';
import Divider from "@mui/material/Divider";
import {Link, useNavigate} from "react-router-dom";
import ListIcon from '@mui/icons-material/List';
import WorkIcon from '@mui/icons-material/Work';
import BuildIcon from '@mui/icons-material/Build';
import DateRangeIcon from '@mui/icons-material/DateRange';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import axios from "axios";
import {API_ROOT, BACKEND_HOST} from "../../api-config";
import {AuthenticationContext} from "../../context/AuthenticationContext";
import db from "../../db";
import {Box} from "@mui/material";
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';

const Navigation = props => {
    const navigate = useNavigate();
    const [, setUser] = useContext(AuthenticationContext);

    const iOS = typeof process != "undefined" && process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

    const handleLogout = () => {
        setUser({});

        db.tables.forEach(function (table) {
            table.clear();
        });

        axios.get(`${API_ROOT}/logout`)
            .then(() => {
                setUser({});
                navigate("/");
            })
            .catch((error) => {
                alert(error);
            });
    };

    return (
        <SwipeableDrawer
            open={props.drawerVisible}
            onClose={props.toggleDrawer(false)}
            onOpen={props.toggleDrawer(true)}
            disableBackdropTransition={!iOS} disableDiscovery={iOS}
        >
            <Box
                sx={{width: 250}}
                role="presentation"
                onClick={props.toggleDrawer(false)}
                onKeyDown={props.toggleDrawer(false)}
            >
                <List>
                    <ListItem component={Link} to="/jobs" button>
                        <ListItemIcon>
                            <WorkIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Aufträge"/>
                    </ListItem>
                </List>
                <Divider/>
                <List>
                    <ListItem component={Link} to="/clients" button>
                        <ListItemIcon>
                            <PeopleIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Kunden"/>
                    </ListItem>
                </List>
                <List>
                    <ListItem component={Link} to="/spare-parts" button>
                        <ListItemIcon>
                            <BuildIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Ersatzteile"/>
                    </ListItem>
                </List>
                <List>
                    <ListItem component={Link} to="/loading-list" button>
                        <ListItemIcon>
                            <ListIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Ladeliste"/>
                    </ListItem>
                </List>
                <List>
                    <ListItem component={Link} to="/timeaccount" button>
                        <ListItemIcon>
                            <TimelapseIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Stundenkonto"/>
                    </ListItem>
                </List>
                <List>
                    <ListItem component={Link} to="/calendar" button>
                        <ListItemIcon>
                            <DateRangeIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Kalender"/>
                    </ListItem>
                </List>
                <Divider/>
                <List>
                    <ListItem component="a" target="_blank" href={BACKEND_HOST + '/backend'} button>
                        <ListItemIcon>
                            <SettingsSuggestIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Backend"/>
                    </ListItem>
                </List>
                <Divider/>
                <List>
                    <ListItem onClick={handleLogout} button>
                        <ListItemIcon>
                            <ExitToAppIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Logout"/>
                    </ListItem>
                </List>
                <Divider/>
                <Box sx={{p: 2, fontSize: 12}}>{process.env.REACT_APP_VERSION}</Box>
            </Box>
        </SwipeableDrawer>
    );
}

export default Navigation;
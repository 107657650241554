import React from "react";
import {AppBar, Box, Toolbar} from "@mui/material";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTransition from "../utils/DialogTransition";

const FullScreenDialog = (props) => {
    const closeDialog = () => {
        props.setOpen(false);
    };

    return (
        <Dialog onClose={closeDialog} fullScreen maxWidth="sm" open={props.open}
                TransitionComponent={DialogTransition}>
            <AppBar sx={{position: 'relative'}}>
                <Toolbar>
                    <Typography sx={{flex: 1}} variant="h6" component="div">
                        {props.title}
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={closeDialog}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box sx={{p: 3}}>
                {props.children}
            </Box>
        </Dialog>
    );
}

export default FullScreenDialog;
import React, {useContext, useEffect, useState} from "react";
import {LayoutContext} from "../../components/Layout/Layout";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {useLiveQuery} from "dexie-react-hooks";
import db from "../../db";
import {isEmpty} from "lodash";
import {Box, Pagination} from "@mui/material";
import {useNavigate} from "react-router-dom";

const List = () => {
    const itemsPerPage = 25;
    const [setLayoutState] = useContext(LayoutContext);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
        setLayoutState({title: 'Kunden'});
    }, [setLayoutState]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const clients = useLiveQuery(
        async () => {
            const clients = await db.clients.orderBy('client_name').toArray();

            setPages(Math.ceil(clients.length / itemsPerPage));

            return clients;
        },
        []
    );

    if (isEmpty(clients)) {
        return <Box sx={{textAlign: 'center', color: 'grey.500', m: 5}}>
            <i>Keine Kunden gefunden!</i>
        </Box>;
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Straße</TableCell>
                            <TableCell align="right">PLZ</TableCell>
                            <TableCell align="right">Ort</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(clients.slice((page - 1) * itemsPerPage, (page - 1) * itemsPerPage + itemsPerPage)).map((client) => (
                            <TableRow
                                hover
                                key={client.client_id}
                                sx={{'&:last-child td, &:last-child th': {border: 0}, '&:hover': {cursor: 'pointer'}}}
                                onClick={() => navigate("/clients/detail/" + client.client_id)}>
                                <TableCell component="th" scope="row">
                                    {client.client_name}
                                </TableCell>
                                <TableCell align="right">{client.client_street}</TableCell>
                                <TableCell align="right">{client.client_zip}</TableCell>
                                <TableCell align="right">{client.client_city}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {pages > 1
                ?
                <Box sx={{display: 'flex', justifyContent: 'center', p: 4}}>
                    <Pagination count={pages} page={page} onChange={handleChangePage}/>
                </Box>
                : ''
            }
        </>
    );
}

export default List;
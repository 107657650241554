import React, {createContext} from 'react';
import usePersistentState from "../hooks/usePersistentState";

export const AuthenticationContext = createContext(null);

export const AuthenticationProvider = props => {
    const [user, setUser] = usePersistentState('user', null);

    return (
        <AuthenticationContext.Provider value={[user, setUser]}>
            {props.children}
        </AuthenticationContext.Provider>
    )
}
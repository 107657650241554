import React from "react";

const Labels = {
    msr1: 'MSR1',
    msr2: 'MSR2',
    msr3: 'MSR3',
    stirling: 'Stirling',
    dachspro: 'Dachs pro 20',
};

const TypeLabel = (props) => {

    return (
        <>{props.type ? (Labels[props.type] ? Labels[props.type] : props.type) : 'Anlagentyp fehlt!'}</>
    );
}

export default TypeLabel;
import React from "react";
import {Divider} from "@mui/material";
import {grey} from "@mui/material/colors";

export default function LightDivider(props) {
    return <Divider
        sx={[
            {
                my: 4,
                color: grey[600],
                fontSize: 14
            },
            ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
        ]}>
        {props.children}
    </Divider>;
}
import React, {useContext} from "react";
import {useNavigate, useParams} from 'react-router-dom';
import {useLiveQuery} from "dexie-react-hooks";
import db from "../../db";
import Typography from "@mui/material/Typography";
import {Avatar, Box, Chip, Grid} from "@mui/material";
import {AuthenticationContext} from "../../context/AuthenticationContext";
import {LayoutContext} from "../../components/Layout/Layout";
import OwnActions from "../../components/Job/Actions/OwnActions";
import OpenActions from "../../components/Job/Actions/OpenActions";
import InstallationPreviewCard from "../../components/Installation/Card/PreviewCard";
import StateStack from "../../components/Job/StateStack";
import {stringAvatar} from "../../utils/stringAvatar";
import TypeLabel from "../../components/Installation/Label/TypeLabel";
import FuelLabel from "../../components/Installation/Label/FuelLabel";
import LightDivider from "../../components/LightDivider";
import PaperAlert from "../../components/PaperAlert";

const Detail = () => {
    let {jobId} = useParams();
    const navigate = useNavigate();
    const [user] = useContext(AuthenticationContext);
    const [setLayoutState] = useContext(LayoutContext);

    const job = useLiveQuery(
        async () => {
            const parsedJobId = parseInt(jobId, 10);
            const job = await db.jobs.get(parsedJobId);

            if (!job) {
                navigate("/jobs", {replace: true});

                return null;
            }

            job.client = await db.clients.get(job.client_id);

            if (job.user_id) {
                job.user = await db.users.get(job.user_id);
            }

            job.installations = await db.installations
                .where({job_id: parsedJobId})
                .toArray();

            setLayoutState({title: "Auftrag #" + job.job_number, showBackButton: true});

            return job
        },
        [jobId]
    );

    if (!job) {
        return null;
    }

    return (
        <>
            <Box sx={{pb: 10}}>
                {job.user_id && !job.job_execution ?
                    <PaperAlert sx={{my: 1}} severity="warning">
                        Kein Ausführungsdatum hinterlegt!
                    </PaperAlert>
                    :
                    ""
                }

                <Box sx={{mt: 1, mx: -1}}>
                    <StateStack job={job}>
                        <Chip
                            avatar={<Avatar {...stringAvatar(job.client.client_name)} />}
                            label={job.client.client_name}
                            variant="outlined"
                            sx={{m: 1}}
                            onClick={() => navigate("/clients/detail/" + job.client.client_id)}
                        />
                    </StateStack>
                </Box>

                <Typography sx={{mt: 2, whiteSpace: 'pre-line'}} variant="body2" color="text.secondary">
                    {job.job_text}
                </Typography>

                {job.user_id === user.id
                    ?
                    <>
                        {job.installations.map(installation => (
                            <React.Fragment key={installation.installation_id}>
                                <LightDivider>
                                    {installation.operating.type_real} ({installation.operating.manufacture ? installation.operating.manufacture + ' - ' : ''}
                                    <TypeLabel type={installation.operating.type}/> / <FuelLabel
                                    fuel={installation.operating.fuel}/>)
                                </LightDivider>

                                <Grid container spacing={2} alignItems="center">
                                    <Grid key={installation.installation_id} item xs={12}>
                                        <InstallationPreviewCard job={job} installation={installation}/>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        ))}

                    </>
                    :
                    ''}

            </Box>

            {!job.user_id ? <OpenActions job={job}/>
                : job.user_id === user.id ? <OwnActions job={job}/>
                    : ""}
        </>
    );
}

export default Detail;
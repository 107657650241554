import React, {useContext} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import SenerTecLogo from "../assets/images/logo.svg";
import {AuthenticationContext} from "../context/AuthenticationContext";
import Typography from "@mui/material/Typography";
import {API_ROOT} from '../api-config';
import {syncTables} from "../db";
import BackgroundImage from "../assets/images/signInBackground.jpg";
import {Checkbox, FormControlLabel, Paper} from "@mui/material";
import preval from 'preval.macro'

const buildDate = preval`module.exports = new Date().toLocaleDateString('de-DE', { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' })`

const SignIn = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [, setUser] = useContext(AuthenticationContext);

    const handleSubmit = (e) => {
        e.preventDefault();

        setMessage('');
        setLoading(true);

        axios.post(`${API_ROOT}/auth`, new FormData(e.target))
            .then(function (response) {
                syncTables(response.data.tables).then(() => {
                    localStorage.setItem('data-hash', response.data.hash);

                    setUser(response.data.user);

                    navigate("../jobs");
                });
            })
            .catch(function (error) {
                setLoading(false);

                if (error.response) {
                    setMessage(error.response.data.message);
                } else {
                    setMessage('Unbekannter Fehler');
                }
            });
    };

    return (
        <Box style={{backgroundImage: `url(${BackgroundImage})`, backgroundSize: 'cover'}}>
            <Container component="main" maxWidth="xs">
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="100vh"
                >
                    <Paper sx={{p: 3, textAlign: 'center'}} elevation={3}>
                        <img alt='SenerTec' src={SenerTecLogo} style={{width: '200px'}}/>
                        <form onSubmit={handleSubmit} noValidate>
                            {message ? <Alert severity="error">{message}</Alert> : ''}

                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="E-Mail Adresse"
                                name="email"
                                autoComplete="email"
                                disabled={loading}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Passwort"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                disabled={loading}
                            />

                            <FormControlLabel
                                control={<Checkbox name="remember" value="remember" color="primary" defaultChecked  />}
                                label="Eingeloggt bleiben"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                sx={{mt: 3, mb: 2}}
                            >
                                Einloggen
                            </Button>
                            {/*<Link component={RouterLink} to="password-forgot" variant="body2">*/}
                            {/*    Passwort vergessen?*/}
                            {/*</Link>*/}
                        </form>
                        <Box sx={{mt: 5}}>
                            <Typography variant="body2" color="textSecondary" align="center">
                                Version: {process.env.REACT_APP_VERSION}
                                <br />
                                <Box component="span" sx={{fontSize: 9, color: 'grey.500', fontStyle: 'italic', mt: '1px'}}>
                                    Build: {buildDate}
                                </Box>
                            </Typography>
                        </Box>
                    </Paper>
                </Box>
            </Container>
        </Box>
    );
}

export default SignIn;
import {Badge, Box} from "@mui/material";
import React from "react";
import Avatar from "@mui/material/Avatar";
import {blue, brown, deepPurple, green, indigo, orange, purple, red, teal} from "@mui/material/colors";
import CheckIcon from "@mui/icons-material/Check";
import SummarizeIcon from "@mui/icons-material/Summarize";
import MessageIcon from "@mui/icons-material/Message";
import HandymanIcon from "@mui/icons-material/Handyman";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import EngineeringIcon from "@mui/icons-material/Engineering";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import UploadFileIcon from "@mui/icons-material/UploadFile";

export const Settings = {
    operating: {
        label: 'Betriebsdaten',
        avatar: <Avatar sx={{bgcolor: red[400]}} variant="rounded">
            <SummarizeIcon/>
        </Avatar>,
        isFinished: function (installation) {
            return installation.operating
                && installation.operating.serial
                && installation.operating.manufacture
                && installation.operating.type_real
                && installation.operating.type
                && installation.operating.fuel;
        }
    },
    service: {
        label: 'Servicebericht',
        avatar: <Avatar sx={{bgcolor: orange[400]}} variant="rounded">
            <MessageIcon/>
        </Avatar>,
        isFinished: function (installation) {
            return installation.service;
        }
    },
    parts: {
        label: 'Ersatzteile',
        avatar: <Avatar sx={{bgcolor: deepPurple[400]}} variant="rounded">
            <HandymanIcon/>
        </Avatar>,
    },
    maintenance: {
        label: 'Wartung',
        avatar: <Avatar sx={{bgcolor: blue[400]}} variant="rounded">
            <ManageAccountsIcon/>
        </Avatar>,
        isFinished: function (installation) {
            return installation.maintenance;
        }
    },
    measure: {
        label: 'Messdaten',
        avatar: <Avatar sx={{bgcolor: teal[400]}} variant="rounded">
            <ThermostatIcon/>
        </Avatar>,
        isFinished: function (installation) {
            return installation.measure;
        }
    },
    evaluation: {
        label: 'Kolben -/ Zylinderbeurteilung',
        avatar: <Avatar sx={{bgcolor: indigo[400]}} variant="rounded">
            <EngineeringIcon/>
        </Avatar>,
        isFinished: function (installation) {
            return installation.evaluation;
        }
    },
    checklists: {
        label: 'Checklisten',
        avatar: <Avatar sx={{bgcolor: purple[400]}} variant="rounded">
            <PlaylistAddCheckIcon/>
        </Avatar>,
    },
    attachments: {
        label: 'Anhänge',
        avatar: <Avatar sx={{bgcolor: brown[400]}} variant="rounded">
            <UploadFileIcon/>
        </Avatar>,
    }
}

const AreaHeader = (props) => {
    return <Box sx={{display: 'flex', alignItems: 'center', my: 1}}>
        {Settings[props.areaIdentifier].isFinished && Settings[props.areaIdentifier].isFinished(props.installation)
            ?
            <Badge
                overlap="circular"
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                badgeContent={
                    <Avatar sx={{
                        width: 22,
                        height: 22,
                        foneSize: 10,
                        bgcolor: green[500],
                        border: `2px solid #fff`
                    }} variant="rounded">
                        {<CheckIcon sx={{width: 1}}/>}
                    </Avatar>
                    /**
                     *                     <Avatar sx={{
                     *                         width: 22,
                     *                         height: 22,
                     *                         fontSize: 10,
                     *                         bgcolor: green[500],
                     *                         border: `2px solid ${theme.palette.background.paper}`
                     *                     }} variant="rounded">
                     *                         {33}
                     *                     </Avatar>
                     */
                }
            >
                {Settings[props.areaIdentifier].avatar}
            </Badge>
            :
            <>{Settings[props.areaIdentifier].avatar}</>
        }
        <Box sx={{ml: 1}}>
            {Settings[props.areaIdentifier].label}
        </Box>
    </Box>;
}

export default AreaHeader;
import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import db, {getUnsyncedChanges, syncTables} from "../db";
import axios from "axios";
import {API_ROOT} from "../api-config";
import {isEmpty} from "lodash";
import {AuthenticationContext} from "./AuthenticationContext";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from '@mui/material/Alert';
import {Slide} from "@mui/material";
import usePersistentState from "../hooks/usePersistentState";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function SlideTransition(props) {
    return <Slide {...props} direction="down"/>;
}

export const SynchronizationContext = createContext(null);

export const SynchronizationProvider = props => {
    const [user, setUser] = useContext(AuthenticationContext);
    const [syncState, setSyncState] = usePersistentState('syncState', {synced: true});
    const [snackbar, setSnackbar] = useState({open: false});

    const enqueueSync = (tableName, primaryKey, data) => {
        data.unsynced = 1;

        db[tableName].update(parseInt(primaryKey, 10), data);

        setSyncState(prevState => {
            return {...prevState, synced: false};
        });
    }

    const sync = useCallback((showNotification) => {
        setSyncState(prevState => {
            return {...prevState, running: true};
        });

        return getUnsyncedChanges()
            .then((unsyncedChanges) => {
                return axios.post(`${API_ROOT}/sync`, {unsyncedChanges: unsyncedChanges})
                    .then(function (response) {
                        setSyncState(prevState => {
                            return {
                                ...prevState,
                                synced: true,
                                running: false,
                                message: 'Daten wurden synchronisiert!'
                            };
                        });

                        if (isEmpty(response.data)) {
                            setUser({});

                            return;
                        }

                        if (showNotification) {
                            setSnackbar({severity: 'success', open: true});
                        }

                        return syncTables(response.data.tables);
                    })
                    .catch((error) => {
                        let message;

                        if (!navigator.onLine) {
                            message = 'Synchronisierung nicht möglich (offline)';
                        } else {
                            if (error.message) {
                                message = error.message
                            } else {
                                message = 'Unbekannter Fehler';
                            }
                        }

                        if (showNotification) {
                            setSnackbar({
                                open: true,
                                severity: !navigator.onLine ? 'info' : 'error',
                                message: message
                            });
                        }

                        setSyncState(prevState => {
                            return {...prevState, running: false, message: message};
                        });

                        if (error.response && error.response.status === 401) {
                            setUser({});
                        }
                    })

            })
    }, [setSyncState, setUser]);

    useEffect(() => {
        // console.log('sync: use effect', syncState);

        const runSync = () => {

            if (isEmpty(user)) {
                return;
            }

            sync().then(() => {
                //console.log('Auto sync successful!')
            });
        };

        const interval = setInterval(() => {
            if (syncState.running) {

                return;
            }

            runSync();
        }, 1000 * 60);

        if (!syncState.initialized || syncState.initialized !== props.bootDate.toISOString()) {
            setSyncState(prevState => {
                return {...prevState, running: false, initialized: props.bootDate.toISOString()};
            });

            runSync();
        }

        return () => {
            clearInterval(interval);
        }
    }, [user, setUser, sync, syncState, props.bootDate, setSyncState])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbar(prevState => {
            return {...prevState, open: false};
        });
    };

    return (
        <SynchronizationContext.Provider value={[syncState, enqueueSync, sync]}>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                TransitionComponent={SlideTransition}
                open={snackbar.open}
                autoHideDuration={2000}
                onClose={handleClose}
            >
                <Alert severity={snackbar.severity ? snackbar.severity : 'success'} sx={{width: '100%'}}>
                    {syncState.message}
                </Alert>
            </Snackbar>
            {props.children}
        </SynchronizationContext.Provider>
    )
}
import React, {createContext, useEffect, useState} from 'react';
import db from "../db";

export const SettingsContext = createContext(null);

export const SettingsProvider = props => {
    const [settings, setSettings] = useState(null);

    useEffect(() => {
        async function fetchSettings() {
            const settings = await db.settings.toArray();

            let result = {};

            settings.forEach((item) => {
                if (Array.isArray(item.value) && item.value.length === 0) {
                    result[item.key] = null;
                } else {
                    result[item.key] = item.value;
                }
            });

            setSettings(result);
        }

        if (settings === null) {
            fetchSettings();
        }
    }, [setSettings, settings]);

    return (
        <SettingsContext.Provider value={[settings]}>
            {props.children}
        </SettingsContext.Provider>
    )
}
import FinishDialog from "../Dialog/FinishDialog";
import CancelDialog from "../Dialog/CancelDialog";
import EditDialog from "../Dialog/EditDialog";
import React, {useState} from "react";
import {Box, Fab} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";

const OwnActions = (props) => {
    const [finishOpen, setFinishOpen] = useState(false);
    const [cancelOpen, setCancelOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);

    return (
        <>
            <Box sx={{position: 'fixed', zIndex: 1000, bottom: 24, left: 24}}>
                <Fab onClick={() => {
                    setEditOpen(true)
                }} sx={{mr: 2}} color="secondary">
                    <EditIcon/>
                </Fab>
            </Box>

            <Box sx={{position: 'fixed', zIndex: 1000, bottom: 24, right: 24}}>
                <Fab onClick={() => {
                    setCancelOpen(true)
                }} sx={{mr: 2}} color="secondary">
                    <CancelIcon/>
                </Fab>

                <Fab disabled={!props.job.job_execution} onClick={() => {
                    setFinishOpen(true)
                }} color="primary" variant="extended">
                    <CheckIcon sx={{mr: 1}}/>
                    Erledigen
                </Fab>
            </Box>

            <FinishDialog job={props.job} open={finishOpen} setOpen={setFinishOpen}/>
            <CancelDialog job={props.job} open={cancelOpen} setOpen={setCancelOpen}/>
            <EditDialog job={props.job} open={editOpen} setOpen={setEditOpen}/>
        </>
    );
}

export default OwnActions;